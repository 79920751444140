<template>
  <Container theme="light" :padding="false">
    <MsgItem v-for="item in list" :key="item.title" :title="item.title" :borderBottom="true" :path="item.path" :isShowArrow="item.isShowArrow" @goToRoute="goToRoute">
      <template v-if="item.title === '谷歌验证'">
        <div class="text_danger">
          {{ item.sub }}
        </div>
        <div slot="sub_title">
          <div class="icon_toggle_button" v-if="!isOpenGoogleSecret">
            <img src="../../../assets/images/public/ic_com_off.png" />
          </div>
          <div class="icon_toggle_button" v-else>
            <img src="../../../assets/images/public/ic_com_on.png" />
          </div>
        </div>
      </template>
      <div slot="sub_title" v-if="item.title === '設定安全密碼'">
        {{ isSecurityCodeContent }}
      </div>
    </MsgItem>
  </Container>
</template>

<script>
import { mapState, mapActions } from "vuex";
import Container from "../components/Container";
import MsgItem from "../components/MsgItem.vue";
import { Message } from "element-ui";
export default {
  components: {
    Container,
    MsgItem,
  },
  data() {
    return {
      isLoading: false,
      platform: "",
      list: [
        {
          title: "修改密码",
          path: "/my/securityCenter/setPassword?reset=password",
          isShowArrow: true,
        },
        {
          title: "設定安全密碼",
          path: "/my/securityCenter/setPassword?reset=paymentPassword",
          isShowArrow: true,
        },
        {
          title: "谷歌验证",
          path: "/my/securityCenter/setGoogleAuthenticator",
          isShowArrow: true,
          sub: "丢失请联系客服",
        },
      ],
    };
  },
  computed: {
    ...mapState("users", ["usersData"]),
    isSecurityCode() {
      return this.usersData.isSecurityCode;
    },
    isSecurityCodeContent() {
      return this.usersData.isSecurityCode ? "已设置" : "未设置";
    },
    isOpenGoogleSecret() {
      return this.usersData.isOpenGoogleSecret;
    },
    isGoogleSecret() {
      return this.usersData.isGoogleSecret;
    },
  },
  methods: {
    ...mapActions("google", ["openGoogleSecret", "closedGoogleSecret"]),
    ...mapActions("users", ["getUsers"]),
    goToRoute(path) {
      if (path === "version") {
        this.checkOSversion();
      } else {
        if (path === "/my/securityCenter/setGoogleAuthenticator") {
          if (!this.isOpenGoogleSecret && !this.isGoogleSecret) {
            this.$router.push({
              path: path,
              query: { step: 1 },
            });
          } else {
            this.toggleGoogleHandler();
          }
        } else {
          if (path === "/my/securityCenter/setPassword?reset=paymentPassword" && !this.isSecurityCode) {
            this.$router.push({ path: "/my/paymentMethod/security" });
          } else {
            this.$router.push({ path: path });
          }
        }
      }
    },
    toggleGoogleHandler() {
      if (this.isOpenGoogleSecret) {
        this.closedGoogleSecret().then(() => {
          this.getUsers();
        });
        Message({
          message: "关闭成功",
          iconClass: "x",
          center: true,
          customClass: "error_message",
        });
      } else {
        this.openGoogleSecret().then(() => {
          this.getUsers();
        });
        Message({
          message: "开启成功",
          iconClass: "x",
          center: true,
          customClass: "error_message",
        });
      }
    },
  },
  mounted() {},
};
</script>

<style lang="scss" scoped>
.btn {
  &_default {
    display: inline-block;
    align-self: center;
    text-align: center;
    vertical-align: middle;
    user-select: none;
    background-color: var(--light-gray);
    border: none;
    padding: 0.75rem 1.5rem;
    border-radius: 1.25rem;
    position: absolute;
    bottom: 10%;
    left: 5%;
    right: 5%;
  }

  &_primary {
    color: white;
    background-color: var(--orange);
  }
}

.icon_toggle_button {
  display: block;
  width: 2.5rem;
  margin: 0.75rem 0 0.75rem 0.75rem;
  transform: translateY(2px);

  img {
    width: 100%;
    height: auto;
    object-fit: cover;
  }
}

.text_danger {
  color: red;
  font-size: 0.85rem;
  padding-left: 0.25rem;
}
</style>
